import React from "react"
import checkMark from "../../assets/icons/noun-check-mark.svg"
import atyetiexpert from "../../assets/icons/atyetiexpert.svg"
import DevSecOps from "../../assets/icons/noun_Shield_1843882.svg"
import cna from "../../assets/icons/cloudNaive.svg"
import Service from "../../components/Shared/Service"
import { graphql } from "gatsby"

const seoTitle = "Cloud Infrastructure "
const mainHeading = "Cloud Infrastructure"
const seoDescription = `Atyeti DevOps practice aims to shorten and modernize the development life cycle providing continuous delivery with high software quality.`
const keyword =
  "devops, Infrastructure as code (IaC), CI/CD, Site reliability engineering (SRE), Monitoring, Iaas, Saas, Paas, Automation, Container Orchestration, Zero Trust Security"
const mainPara = ` Atyeti DevOps practice aims to shorten and modernize the
development life cycle providing continuous delivery with high
software quality. Automation is a core principle for achieving
DevOps success and CI/CD is a critical component.`

const serviceIcons = [
  { id: 1, name: "Infrastructure as code (IaC)", icon: checkMark },
  { id: 2, name: " CI/CD", icon: checkMark },
  { id: 3, name: " Site reliability engineering (SRE)", icon: checkMark },
  { id: 4, name: "Monitoring", icon: checkMark },
  { id: 5, name: " Iaas, Saas, Paas", icon: checkMark },
  { id: 6, name: "Automation", icon: checkMark },
  { id: 7, name: "Container Orchestration", icon: checkMark },
  { id: 8, name: " Zero Trust Security", icon: checkMark },
]

// const section2Heading = `WHAT WE DO`
const section2Para = `Atyeti can help with increasing your cloud maturity.  Let us help you Automate, Secure, and reduce Risk using modern multi cloud strategies.`

const cardItems = [
  {
    id: 1,
    icon: cna,
    heading: `Infrastructure Modernization`,
    listItems: (
      <>
        <li>
          Leverage Modern Tools across the Software Delivery Lifecycle and
          Simplify your DevOps processes - CI/CD & GitOps, MLOps and much
          more
        </li>
        <li>
          Atyeti can help you: Code, Build and Test, Secure, Deploy, Security
          Automate provisioning Compliance Management of cloud infrastructure
        </li>
        <li>Choose your path to the cloud—lift and shift or refactor. Get the foundational services right</li>
      </>
    ),
  },
  {
    id: 2,
    icon: DevSecOps,
    heading: `DevSecOps`,
    listItems: (
      <>
        <li>
          Shift towards a DevSecOps mindset and bring individuals across all
          technology disciplines to a higher level of proficiency in security.
        </li>
        <li>
          Tests for potential security exploits to build business-driven
          security services.
        </li>
        <li>
          Ensures security is built into applications rather than being bolted
          on afterwards.Atyeti’s emphasis is on Automating tasks.</li>
      </>
    ),
  },
  {
    id: 3,
    icon: atyetiexpert,
    heading: `Atyeti expertise`,
    listItems: (
      <>
        <li>Application Modernization.</li>
        <li>Data Center Migration.</li>
        <li>Day 0, Day 1 and Day 2 Services.</li>
        <li>Application Migration factory.</li>
        <li>Tool expertise- HashiCorp Terraform, Vault, Harness – CI/CD, GCP, AWS and Azure native services etc.</li>
      </>
    ),
  },
]
const Services4 = ({ data }) => {
  return (
    <>
      <Service
        seoTitle={seoTitle}
        mainHeading={mainHeading}
        mainPara={mainPara}
        serviceIcons={serviceIcons}
        // section2Heading={section2Heading}
        cardItems={cardItems}
        section2Para={section2Para}
        caseItems={data}
        seoDescription={seoDescription}
        keyword={keyword}
        cloudOperatingModal
        className="devops"
      />
    </>
  )
}
export const query = graphql`
  query DevopsCaseQuery {
    casestudyJson(mainheading: { eq: "cloud-infrastructure" }) {
      data {
        heading
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        para
        slug
        subheading
      }
      bannerimage {
        heading
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
export default Services4
